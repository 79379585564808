import {
  IMG
} from '../actions/imageActions';

const initialImg = { img: '' };

/**

Reducer for managing the image data.
@param {Object} img - The image data state.
@param {Object} action - The dispatched action.
@returns {Object} - The updated image data state.
*/
export function imageReducer(img = initialImg, action) {
  switch (action.type) {
    case IMG:
      return { ...img, img: action.payload };

    default:
      return img;
  }
}
