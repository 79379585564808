/**

API functions for fetching weather details and image details.
*/
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { BMS_URL, WEATHER_URL } from './constants/urls';

/**

Fetches weather details from the weather API.
@param {Object} params - Parameters for the weather request.
@param {string} params.zipCode - Zip code for the weather request.
@param {string} params.floorArea - Floor area for the weather request.
@param {string} params.buildingType - Building type for the weather request.
@param {string} params.energyCost - Energy cost for the weather request.
@param {Object} params.userDetails - User details for the weather request.
@returns {Promise} - Promise representing the weather API request.
*/
export const getWeatherDetails = ({
  zipCode, floorArea, buildingType, energyCost, userDetails
}) => axios.post(WEATHER_URL, {
  zipcode: zipCode,
  area: floorArea.replaceAll(',', ''),
  building_type: buildingType,
  energy_cost: energyCost.replaceAll(',', ''),
  user_details: userDetails
});

/**

Fetches image details from the BMS API.
@param {Object} params - Parameters for the image request.
@param {Object} params.form - Form data for the image request.
*/
export const getImageDetails = ({
  form
}) => axios.post(BMS_URL, form, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }

});
