export const IMG = 'PREV';

/**

Action creator for setting the image in the form.
@param {string} img - The image URL or data.
@returns {Function} - The Redux thunk function.
*/
export const setIMG = (img) => (dispatch) => {
  dispatch({
    type: IMG,
    payload: img
  });
};
