import { BACKEND_URL } from '../config/config';

/**
 * URL for weather data API.
 * Combines the backend URL with the "/weather/" endpoint.
 */
export const WEATHER_URL = `${BACKEND_URL}/weather/`;

/**
 * URL for BMS (Building Management System) data API.
 * Combines the backend URL with the "/bms" endpoint.
 */
export const BMS_URL = `${BACKEND_URL}/bms`;
