/**
 * Color constant for blue.
 */
export const blue = 'rgba(13, 71, 161, 255)';

/**
 * Color constant for green.
 */
export const green = 'rgb(24, 130, 13)';

/**
 * Color constant for red.
 */
export const red = 'rgb(255, 76, 48)';

/**
 * Color constant for black.
 */
export const black = 'rgb(25, 25, 25)';

/**
 * Colors used for different steps in the form.
 * Contains properties for the title and marker colors in different states.
 */
export const stepsColors = {
  title: {
    text: {
      normal: 'rgba(0, 0, 0, 255)',
      active: '#005f73',
      completed: '#005f73'
    }
  },
  marker: {
    text: {
      normal: 'rgba(133, 153, 180, 255)',
      active: green,
      completed: 'white'
    },
    line: {
      normal: 'rgba(133, 153, 180, 255)',
      active: green,
      completed: green
    }
  }
};

/**
 * Font size constants for different row sizes.
 */
export const rowFontSize = {
  base: '10',
  sm: '10',
  md: '12',
  lg: '14',
  xl: '16'
};

/**
 * Background color constant.
 */
export const bgColor = 'rgb(250, 250, 250)';
