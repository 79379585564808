/**

Main entry point of the React Native app.
This app displays an image processing tool that allows users to upload images
and apply color correction algorithms to them.
The app uses React Navigation to manage navigation between screens, and
Redux to manage the app's state. The app also uses Native Base for styling and layout
and lazy loading to optimize
performance by only loading components when they are needed.
The app registers a service worker for offline functionality.
@module App
*/
import React, { lazy, Suspense } from 'react';
import { Center, NativeBaseProvider } from 'native-base';
import { Provider } from 'react-redux';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ImageBackground } from 'react-native';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
import Store from './src/redux/store';
import Loader from './src/components/Loader';
import AppHeader from './src/components/Header';
import Processor from './src/containers/Processor';

const bg = require('./assets/bg.jpg');

const Forms = lazy(() => import('./src/containers/Forms'));
const Details = lazy(() => import('./src/containers/Details'));

const Stack = createNativeStackNavigator();

const linking = {
  config: {
    screens: {
      'DeltaE+': '',
      Report: 'report',
      ImageProcessor: 'imageprocessor'
    }
  }
};

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent'
  }
};

const Navigation = () => (
  <Suspense fallback={<Center h="100%"><Loader /></Center>}>
    <ImageBackground
      style={{ width: '100%', height: '100%', resizeMode: 'cover' }}
      source={bg}
    >
      <NavigationContainer theme={navTheme} linking={linking}>
        <Stack.Navigator
          screenOptions={{ header: AppHeader }}
        >
          <Stack.Screen
            name="ImageProcessor"
            component={Processor}
          />
          <Stack.Screen
            name="DeltaE+"
            component={Forms}
          />
          <Stack.Screen
            name="Report"
            component={Details}
          />

        </Stack.Navigator>
      </NavigationContainer>
    </ImageBackground>
  </Suspense>
);

const App = () => (
  <Provider store={Store}>
    <NativeBaseProvider>
      <Navigation />
    </NativeBaseProvider>
  </Provider>
);

export default App;

serviceWorkerRegistration.register();
