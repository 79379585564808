import React from 'react';
import ScrollHOC from '../HOC/ScrollHOC';
import ImageCard from '../components/Processor/ImageCard';

/**
 * Processor component.
 * Renders the Processor page with a scrollable and centered layout.
 * Uses the ScrollHOC to wrap the ImageCard component.
 */
const Processor = () => {
  const ProcessorPage = ScrollHOC(ImageCard);
  return (
    <ProcessorPage p={10} bw={1} />
  );
};

export default Processor;
