export const PREV = 'PREV';
export const NEXT = 'NEXT';
export const ZIP_CODE = 'ZIP_CODE';
export const FLOOR_AREA = 'FLOOR_AREA';
export const BUILDING_TYPE = 'BUILDING_TYPE';
export const ENERGY_COST = 'ENERGY_COST';
export const MAIL = 'MAIL';
export const TITLE = 'TITLE';
export const COMPANY = 'COMPANY';
export const PHONE = 'PHONE';
export const ADDRESS = 'ADDRESS';
export const TEMPERATURE = 'TEMPERATURE';
export const HUMIDITY = 'HUMIDITY';

/**

Action creator for navigating to the previous step in the form.
@param {number} step - The current step number.
@returns {Function} - The Redux thunk function.
*/
export const goPrev = (step) => (dispatch) => {
  dispatch({
    type: PREV,
    payload: step
  });
};

/**

Action creator for navigating to the next step in the form.
@param {number} step - The current step number.
@returns {Function} - The Redux thunk function.
*/
export const goNext = (step) => (dispatch) => {
  dispatch({
    type: NEXT,
    payload: step
  });
};

/**

Action creator for setting the zip code in the form.
@param {string} zipcode - The zip code value.
@returns {Function} - The Redux thunk function.
*/
export const setZipCode = (zipcode) => (dispatch) => {
  dispatch({
    type: ZIP_CODE,
    payload: zipcode
  });
};

/**

/**

Action creator for setting the floor area in the form.
@param {string} floorArea - The floor area value.
@returns {Function} - The Redux thunk function.
*/
export const setFloorArea = (floorArea) => (dispatch) => {
  dispatch({
    type: FLOOR_AREA,
    payload: floorArea
  });
};

/**

Action creator for setting the building type in the form.
@param {string} buildingType - The building type value.
@returns {Function} - The Redux thunk function.
*/
export const setBuildingType = (buildingType) => (dispatch) => {
  dispatch({
    type: BUILDING_TYPE,
    payload: buildingType
  });
};

/**

Action creator for setting the energy cost in the form.
@param {string} energyCost - The energy cost value.
@returns {Function} - The Redux thunk function.
*/
export const setEnergyCost = (energyCost) => (dispatch) => {
  dispatch({
    type: ENERGY_COST,
    payload: energyCost
  });
};

/**

Action creator for setting the email in the form.
@param {string} payload - The email value.
@returns {Function} - The Redux thunk function.
*/
export const setMail = (payload) => (dispatch) => {
  dispatch({
    type: MAIL,
    payload
  });
};

/**

Action creator for setting the title in the form.
@param {string} payload - The title value.
@returns {Function} - The Redux thunk function.
*/
export const setTitle = (payload) => (dispatch) => {
  dispatch({
    type: TITLE,
    payload
  });
};

/**

Action creator for setting the company in the form.
@param {string} payload - The company value.
@returns {Function} - The Redux thunk function.
*/
export const setCompany = (payload) => (dispatch) => {
  dispatch({
    type: COMPANY,
    payload
  });
};

export const setPhone = (userDetails) => (dispatch) => {
  dispatch({
    type: PHONE,
    payload: userDetails
  });
};

export const setAddress = (userDetails) => (dispatch) => {
  dispatch({
    type: ADDRESS,
    payload: userDetails
  });
};

/**

Action creator for setting the temperature in the form.
@param {string} payload - The temperature value.
@returns {Function} - The Redux thunk function.
*/
export const setTemperature = (payload) => (dispatch) => {
  dispatch({
    type: TEMPERATURE,
    payload
  });
};

/**

Action creator for setting the humidity in the form.
@param {string} payload - The humidity value.
@returns {Function} - The Redux thunk function.
*/
export const setHumidity = (payload) => (dispatch) => {
  dispatch({
    type: HUMIDITY,
    payload
  });
};
