import {
  PREV, NEXT, FLOOR_AREA, BUILDING_TYPE, ZIP_CODE, ENERGY_COST,
  MAIL, COMPANY, TITLE, TEMPERATURE, HUMIDITY, PHONE, ADDRESS
} from '../actions/formActions';

const initialStep = 0;

const initialForm = {
  zipCode: '',
  floorArea: '',
  buildingType: '',
  energyCost: ''
};

const intialUser = {
  mail: '',
  company: '',
  title: '',
  phone: '',
  address: ''
};

const intialAHU = {
  temperature: '',
  humidity: ''
};

/**

Reducer for managing the form data related to zip code, floor area, building type, and energy cost.
@param {Object} form - The form data state.
@param {Object} action - The dispatched action.
@returns {Object} - The updated form data state.
*/

export function formReducer(form = initialForm, action) {
  switch (action.type) {
    case ZIP_CODE:
      return { ...form, zipCode: action.payload };
    case FLOOR_AREA:
      return { ...form, floorArea: action.payload };
    case BUILDING_TYPE:
      return { ...form, buildingType: action.payload };
    case ENERGY_COST:
      return { ...form, energyCost: action.payload };
    default:
      return form;
  }
}

/**

Reducer for managing the user data related to mail, company, and title.
@param {Object} user - The user data state.
@param {Object} action - The dispatched action.
@returns {Object} - The updated user data state.
*/
export function userReducer(user = intialUser, action) {
  switch (action.type) {
    case MAIL:
      return { ...user, mail: action.payload };
    case TITLE:
      return { ...user, title: action.payload };
    case COMPANY:
      return { ...user, company: action.payload };
    case PHONE:
      return { ...user, phone: action.payload };
    case ADDRESS:
      return { ...user, address: action.payload };
    default:
      return user;
  }
}

/**

Reducer for managing the AHU (Air Handling Unit) data related to temperature and humidity.
@param {Object} ahu - The AHU data state.
@param {Object} action - The dispatched action.
@returns {Object} - The updated AHU data state.
*/
export function ahuReducer(ahu = intialAHU, action) {
  switch (action.type) {
    case TEMPERATURE:
      return { ...ahu, temperature: action.payload };
    case HUMIDITY:
      return { ...ahu, humidity: action.payload };
    default:
      return ahu;
  }
}

/**

Reducer for managing the current step in the form.
@param {number} step - The current step.
@param {Object} action - The dispatched action.
@returns {number} - The updated current step.
*/
function stepReducer(step = initialStep, action) {
  switch (action.type) {
    case PREV:
      return step - 1;
    case NEXT:
      return step + 1;
    default:
      return step;
  }
}

export default stepReducer;
